import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useDevStore = create(
  persist(
    (set, get) => ({
      isDev: false,
      setIsDev: (isDev) => set({ isDev }),
      devFlags: [],
      setDevFlags: (devFlags) => set({ devFlags }),
      enabledFlags: [],
      setEnabledFlags: (enabledFlags) => set({ enabledFlags }),
      enableFlag: (flag) => set({ enabledFlags: [...get().enabledFlags, flag] }),
      disableFlag: (flag) => set({ enabledFlags: get().enabledFlags.filter((f) => f !== flag) }),
      hasFlag: (flag) => get().enabledFlags.includes(flag),
    }),
    {
      name: 'dev-storage',
    }
  )
)

export default useDevStore
