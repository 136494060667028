import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePaymentStore = create(
  persist(
    (set, get) => ({
      billingRedirect: null,
      payments: [],
      addPayment: (payment) =>
        set((state) => ({ payments: [...state.payments, payment] })),
      hasPayment: (payment) => {
        const { payments } = get()
        if (!payments || payments?.length === 0) return false
        return payments.includes(payment)
      },
      setBillingRedirect: (url) => set({ billingRedirect: url }),
    }),
    {
      name: 'payment-storage',
    }
  )
)

export default usePaymentStore
