import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import {
  CAMPAIGN_ALGO_PRICE,
  CAMPAIGN_ALGO_STEP,
  CAMPAIGN_BOOST_PRICE,
  CAMPAIGN_BUDGET_OPTIONS,
  CAMPAIGN_MANAGER_PRICE,
  TIKTOK_TALENTS_OPTIONS,
} from '@/components/VipCampaign/config'
import { calculateSpotifyStreamRanges } from '@/components/VipCampaign/utils'
import {
  CREDIT_DISCOUNT_PERCENTAGE,
  PLANS,
  VIP_CAMPAIGN_COMMISSION_PERCENTAGE,
} from '@/data'
import { calculateVAT } from '@/utils'

const defaultForm = {
  typologies: ['spotify'],
  age: ['all'],
  gender: ['all'],
  duration: [7],
  country: ['it'],
  budget: {
    manager: false,
    algo: false,
    boost: false,
    commission: 0,
    spotify: 50,
    tiktok: 50,
    youtube: 50,
    instagram: 500,
    podcast: 500,
    tiktokTalents: TIKTOK_TALENTS_OPTIONS[0],
  },
  info: {
    spotify: {
      artist: null,
      track: null,
      artistId: null,
      trackId: null,
      genres: [],
    },
    tiktok: {
      handle: null,
      videoUrl: null,
      videoCode: null,
    },
    tiktokTalents: {
      soundUrl: null,
      minute: null,
      minuteStart: '00:30',
      minuteEnd: '01:30',
      lyrics: null,
    },
    instagram: {
      postUrl: null,
      name: null,
      phone: null,
      email: null,
    },
    podcast: {
      show: null,
      showId: null,
      name: null,
      phone: null,
      email: null,
    },
    youtube: {
      channelUrl: null,
      videoUrl: null,
    },
  },
  extra: [],
  notes: null,
}

export const useVipCampaignStore = create(
  persist(
    (set, get) => ({
      loading: false,
      success: false,
      error: false,
      step: null,
      form: defaultForm,
      setForm: (form) => {
        set({ form })
      },
      setSuccess: (success) => {
        set({ success, error: false, loading: false })
      },
      setError: (error) => {
        set({ error, success: false, loading: false })
      },
      setStep: (step) => {
        set({ step })
      },
      setLoading: (loading) => {
        set({ loading })
      },
      resetForm: () => {
        set({ form: defaultForm })
      },
      resetFeedback: () => {
        set({ success: false, error: false, loading: false })
      },
      resetExtra: () => {
        set((state) => ({
          form: {
            ...state.form,
            extra: [],
          },
        }))
      },
      resetAlgo: () => {
        set((state) => ({
          form: {
            ...state.form,
            budget: {
              ...state.form.budget,
              algo: false,
            },
          },
        }))
      },
      resetCountry: () => {
        set((state) => ({
          form: {
            ...state.form,
            country: [],
          },
        }))
      },
      impressions: {
        spotify: {
          plays: 0,
          playlists: 0,
          streams: 0,
        },
        tiktok: {
          views: 0,
        },
        tiktokTalents: {
          reach: 0,
          costPerTalent: 0,
          talents: 0,
        },
        youtube: {
          views: 0,
        },
      },
      payment: null,
      getImpressions: () => {
        const { impressions } = get()
        return impressions
      },
      getCommissions: () => {
        const { form } = get()
        return form.budget.commission
      },
      getPaymentTotal: () => {
        const { payment } = get()
        return payment
      },
      getMaxCredits: (campaign) => {
        let spotifyCredits = 0
        let tikTokCredits = 0
        let youtubeCredits = 0

        if (campaign?.typologies.includes('spotify')) {
          spotifyCredits +=
            campaign?.budget?.spotify * CREDIT_DISCOUNT_PERCENTAGE
        }

        return spotifyCredits + tikTokCredits + youtubeCredits
      },
      getTikTokTalentsTotal: () => {
        const impressions = get().impressions
        const talents = impressions?.tiktokTalents?.talents
        const costPerTalent = impressions?.tiktokTalents?.costPerTalent

        if (!talents || !costPerTalent) return 0
        return parseFloat(talents * costPerTalent)
      },
      setSpotifyImpressions: (impressions) => {
        set((state) => ({
          impressions: {
            ...state.impressions,
            spotify: {
              ...state?.impressions?.spotify,
              ...impressions,
            },
          },
        }))
      },
      setTikTokImpressions: (impressions) => {
        set((state) => ({
          impressions: {
            ...state.impressions,
            tiktok: impressions,
          },
        }))
      },
      setTikTokTalentsImpressions: (impressions) => {
        set((state) => ({
          impressions: {
            ...state.impressions,
            tiktokTalents: impressions,
          },
        }))
      },
      setYouTubeImpressions: (impressions) => {
        set((state) => ({
          impressions: {
            ...state.impressions,
            youtube: impressions,
          },
        }))
      },
      setCommission: (commission) => {
        set((state) => ({
          form: {
            ...state.form,
            budget: {
              ...state.form.budget,
              commission,
            },
          },
        }))
      },
      addItem: (item) => {
        set((state) => ({
          form: {
            ...state.form,
            extra: [...state.form.extra, item],
          },
        }))
      },
      setItem: (item) => {
        set((state) => ({
          form: {
            ...state.form,
            extra: [item],
          },
        }))
      },
      resetItems: () => {
        set((state) => ({
          form: {
            ...state.form,
            extra: [],
          },
        }))
      },
      hasPlan: (plan) => {
        const extra = get().form.extra

        if (plan?.name === PLANS.FREE && !extra?.length > 0) {
          return false
        }

        return true
      },
      calculatePaymentTotal: (campaign, plan) => {
        let total = 0

        if (campaign?.typologies.includes('spotify')) {
          total += parseFloat(campaign?.budget?.spotify)
        }

        if (campaign?.typologies.includes('tiktok')) {
          total += parseFloat(campaign?.budget?.tiktok)
        }

        if (campaign?.typologies.includes('youtube')) {
          total += parseFloat(campaign?.budget?.youtube)
        }

        if (campaign?.typologies.includes('tiktokTalents')) {
          const talentsTotal = get().getTikTokTalentsTotal()
          total += parseFloat(talentsTotal)
        }

        if (campaign?.budget?.manager) {
          total += CAMPAIGN_MANAGER_PRICE
        }

        if (campaign?.budget?.algo) {
          total += CAMPAIGN_ALGO_PRICE
        }

        if (campaign?.budget?.boost) {
          total += CAMPAIGN_BOOST_PRICE
        }

        const vipTotal = total
        const extra = campaign?.extra
        if (extra?.length > 0) {
          extra.forEach((addOn) => {
            if (!addOn) return
            total += parseFloat(addOn?.price)
          })
        }

        let totalWithVAT = calculateVAT(total)

        const commission = get().calculateCommission(vipTotal)
        if (commission > 0 && plan?.name === PLANS.FREE) {
          total += commission
          totalWithVAT += commission
        }

        const payment = {
          total,
          vipTotal,
          totalWithVAT,
          vat: totalWithVAT - total,
        }

        set({ payment })
        return payment
      },
      calculateCommission: (total) => {
        const commission = total * (VIP_CAMPAIGN_COMMISSION_PERCENTAGE / 100)
        get().setCommission(commission)
        return commission
      },
      calculateImpressions: (id, campaign, plan) => {
        const MULTIPLIER = 2
        const option = CAMPAIGN_BUDGET_OPTIONS.find(
          (option) => option.id === id
        )
        const step = option.step || 1

        const typologies = campaign.typologies
        const countries = campaign.country

        const europeanCountries = ['it', 'fr', 'de', 'es']
        const isEurope =
          countries &&
          countries.some((country) => europeanCountries.includes(country))

        const englishCountries = ['uk', 'us']
        const isEnglish =
          countries &&
          countries.some((country) => englishCountries.includes(country))

        // const emergingCountries = ['india', 'south_america', 'africa']
        // const isEmerging = countries && countries.some((country) =>
        //   emergingCountries.includes(country)
        // )

        const value = campaign.budget[id]
        if (!value) return 0

        if (typologies.includes(option.id) && id === 'youtube') {
          if (isEurope) {
            const minViews = (value / step) * 860 * MULTIPLIER
            const maxViews = (value / step) * 1522 * MULTIPLIER

            get().setYouTubeImpressions({
              views: {
                min: minViews,
                max: maxViews,
              },
            })
          }

          if (isEnglish) {
            const minViews = (value / step) * 283 * MULTIPLIER
            const maxViews = (value / step) * 540 * MULTIPLIER

            get().setYouTubeImpressions({
              views: {
                min: minViews,
                max: maxViews,
              },
            })
          }
        }

        if (typologies.includes(option.id) && id === 'spotify') {
          const minRangePlaylists = (value / step) * 50
          const maxRangePlaylists = (value / step) * 100

          const minRangePlays = (value / step) * 100
          const maxRangePlays = (value / step) * 500

          const popularity = campaign?.info?.spotify?.artist?.popularity || 0
          const streams = calculateSpotifyStreamRanges(
            value,
            CAMPAIGN_ALGO_STEP,
            popularity
          )

          get().setSpotifyImpressions({
            plays: {
              min: minRangePlays,
              max: maxRangePlays,
            },
            playlists: {
              min: minRangePlaylists,
              max: maxRangePlaylists,
            },
            streams,
          })
        }

        if (typologies.includes(option.id) && id === 'tiktok') {
          if (isEurope) {
            const minViews = (value / step) * 5421 * MULTIPLIER
            const maxViews = (value / step) * 10190 * MULTIPLIER

            get().setTikTokImpressions({
              views: {
                min: minViews,
                max: maxViews,
              },
            })
          }

          if (isEnglish) {
            const minViews = (value / step) * 3040 * MULTIPLIER
            const maxViews = (value / step) * 5250 * MULTIPLIER

            get().setTikTokImpressions({
              views: {
                min: minViews,
                max: maxViews,
              },
            })
          }
        }

        if (typologies.includes(option.id) && id === 'tiktokTalents') {
          let minReach = 0
          let maxReach = 0
          let costPerTalent = 0
          const talents = campaign.budget[id].value

          if (talents <= 10) {
            minReach = 230000
            maxReach = 350000
            costPerTalent = 17 * MULTIPLIER
          } else if (talents <= 15) {
            minReach = 300000
            maxReach = 450000
            costPerTalent = 16.66 * MULTIPLIER
          } else if (talents <= 30) {
            minReach = 600000
            maxReach = 1000000
            costPerTalent = 15 * MULTIPLIER
          } else if (talents <= 50) {
            minReach = 1000000
            maxReach = 1200000
            costPerTalent = 13 * MULTIPLIER
          } else if (talents <= 70) {
            minReach = 1200000
            maxReach = 2000000
            costPerTalent = 12 * MULTIPLIER
          } else if (talents <= 115) {
            minReach = 2000000
            maxReach = 3000000
            costPerTalent = 10.45 * MULTIPLIER
          } else if (talents <= 150) {
            minReach = 3000000
            maxReach = 5000000
            costPerTalent = 10 * MULTIPLIER
          }

          get().setTikTokTalentsImpressions({
            talents,
            costPerTalent,
            reach: {
              min: minReach,
              max: maxReach,
            },
          })
        }

        get().calculatePaymentTotal(campaign, plan)
      },
      getFormData: () => {
        const { form, impressions, payment } = get()

        const formData = {
          ...form,
          estimate: impressions,
          payment,
        }

        /* Not Spotify */
        if (!form?.typologies.includes('spotify')) {
          delete formData.budget.algo
          delete formData.budget.spotify
          delete formData.info.spotify
          delete formData.estimate.spotify
        }

        /* Not TikTok */
        if (!form?.typologies.includes('tiktok')) {
          delete formData.budget.tiktok
          delete formData.info.tiktok
          delete formData.estimate.tiktok
        }

        /* TikTok Talents */
        if (form?.typologies.includes('tiktokTalents')) {
          delete formData.age
          delete formData.duration
          delete formData.gender

          let tiktokBudget
          if (typeof form.budget.tiktokTalents === 'object') {
            tiktokBudget = form.budget.tiktokTalents?.value
          } else {
            tiktokBudget = form.budget.tiktokTalents
          }

          formData.budget.tiktokTalents = tiktokBudget
        }

        /* Not TikTok Talents */
        if (!form?.typologies.includes('tiktokTalents')) {
          delete formData.budget.tiktokTalents
          delete formData.info.tiktokTalents
          delete formData.estimate.tiktokTalents
        }

        /* Not YouTube */
        if (!form?.typologies.includes('youtube')) {
          delete formData.budget.youtube
          delete formData.info.youtube
          delete formData.estimate.youtube
        }

        /* Instagram */
        if (form?.typologies.includes('instagram')) {
          formData.budget.instagram = form.budget.instagram[0]
        }

        /* Not Instagram */
        if (!form?.typologies.includes('instagram')) {
          delete formData.budget.instagram
          delete formData.info.instagram
        }

        /* Podcast */
        if (form?.typologies.includes('podcast')) {
          formData.budget.podcast = form.budget.podcast[0]
        }

        /* Not Podcast */
        if (!form?.typologies.includes('podcast')) {
          delete formData.budget.podcast
          delete formData.info.podcast
        }

        /* Spotify Only */
        if (
          !form?.typologies.includes('youtube') &&
          !form?.typologies.includes('tiktok') &&
          !form?.typologies.includes('tiktokTalents')
        ) {
          delete formData.age
          delete formData.country
          delete formData.duration
          delete formData.gender
        }

        return formData
      },
    }),
    {
      name: 'vip-campaign-storage',
    }
  )
)

export default useVipCampaignStore
