import { debounce } from 'lodash-es'
import { create } from 'zustand'
import { matchfyApi } from '@/api'
import {
  CHAT_SYNC_INTERVAL,
  CHAT_TYPES,
  DEVELOPMENT
} from '@/data'

const updateChatList = (currentList, newChats) => {
  return newChats.reduce(
    (acc, chat) => {
      const existingIndex = acc.findIndex((c) => c.sid === chat.sid)
      if (existingIndex > -1) {
        acc[existingIndex] = chat
      } else {
        acc.push(chat)
      }
      return acc
    },
    [...currentList]
  )
}

export const useChatStore = create((set, get) => ({
  activeSid: null,
  activeChat: null,
  pushProId: null,
  pushProHasDispute: false,
  chatList: [],
  archiveList: [],
  blockedList: [],
  chats: {},
  chatMessages: {},
  loadedChats: [],
  updatedChats: [],
  chatTotal: 0,
  showArchive: false,
  filter: 'all',
  loading: false,
  success: false,
  error: false,
  totalReached: false,
  setActiveSid: (sid) => set({ activeSid: sid }),
  setActiveChat: (chat) => set({ activeChat: chat }),
  setPushProId: (id) => set({ pushProId: id }),
  setPushProHasDispute: (hasDispute) => set({ pushProHasDispute: hasDispute }),
  setChatTotal: (total) => set({ chatTotal: total }),
  setTotalReached: (reached) => set({ totalReached: reached }),
  setFilter: (filter) => set({ filter }),
  setShowArchive: (show) => set({ showArchive: show }),
  setChatList: (chats) => {
    const currentChats = get().chatList
    const updatedChats = updateChatList(currentChats, chats)
    set({ chatList: updatedChats })
  },
  setArchiveList: (chats) => {
    const currentChats = get().archiveList
    const updatedChats = updateChatList(currentChats, chats)
    set({ archiveList: updatedChats })
  },
  setChats: (chats) => set({ chats }),
  setChat: (chatSid, chat) => {
    set((state) => ({
      chats: {
        ...state.chats,
        [chatSid]: chat,
      },
    }))
  },
  getActiveChat: () => {
    if (!get().activeSid) return null
    const activeSid = get().activeSid
    return get().chats[activeSid]
  },
  getChatMessages: (chatSid) => {
    if (!chatSid) {
      const activeSid = get().activeSid
      return get().chatMessages[activeSid]
    }

    return get().chatMessages[chatSid]
  },
  getChatMessagesCount: (chatSid) => {
    if (!chatSid) {
      const activeSid = get().activeSid
      return get().chatMessages[activeSid].length || 0
    }

    return get().chatMessages[chatSid].length || 0
  },
  getUserMessageCount: (userId, chatSid) => {
    const chatMessages = chatSid
      ? get().chatMessages[chatSid]
      : get().chatMessages[get().activeSid]

    const messageCount = chatMessages.filter(message => message.author === userId).length || 0

    return messageCount
  },
  isUsersFirstMessage: (userId, chatSid) => {
    const chatMessages = chatSid
      ? get().chatMessages[chatSid]
      : get().chatMessages[get().activeSid]

    if (!chatMessages || chatMessages.length === 0) {
      return false
    }

    return chatMessages.filter(message => message.author === userId).length === 1
  },
  setChatMessages: (chatSid, messages, mode = 'prepend') => {
    const currentMessages = get().chatMessages[chatSid] || []
    const extractedMessages = get().extractMessagesData(messages)

    const updatedMessages =
      mode === 'prepend'
        ? [...extractedMessages, ...currentMessages]
        : [...currentMessages, ...extractedMessages]

    set((state) => ({
      chatMessages: {
        ...state.chatMessages,
        [chatSid]: updatedMessages,
      },
    }))
  },
  setLoadedChats: (chatSid) => {
    if (get().loadedChats.includes(chatSid)) return

    set((state) => ({
      loadedChats: [...state.loadedChats, chatSid],
    }))
  },
  resetChat: () => {
    const chatSid = get().activeSid
    set({ chat: null })

    set((state) => {
      const messages = { ...state.chatMessages }
      delete messages[chatSid]

      return {
        chatMessages: messages,
      }
    })
  },
  resetFeedback: () => set({ success: false, error: false }),
  extractMessagesData: (messages) => {
    return messages.map((msg) => ({
      sid: msg.sid,
      body: msg.body,
      author: msg.author,
      dateCreated: msg.dateCreated,
      attributes: msg.attributes,
      index: msg.index,
      blockStart: msg.blockStart,
      blockEnd: msg.blockEnd,
    }))
  },
  moveChat: (sourceListName, destinationListName, chatSid) => {
    const sourceList = get()[sourceListName]
    const destinationList = get()[destinationListName]
    const chatIndex = sourceList.findIndex((c) => c.sid === chatSid)

    if (chatIndex === -1) return

    const chat = sourceList[chatIndex]
    const updatedSourceList = sourceList.filter((c) => c.sid !== chatSid)
    const updatedDestinationList = updateChatList(destinationList, [chat])

    updatedDestinationList.sort(
      (a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated)
    )
    updatedSourceList.sort(
      (a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated)
    )

    set({
      [sourceListName]: updatedSourceList,
      [destinationListName]: updatedDestinationList,
    })
  },
  syncChats: async (queryClient) => {
    console.log('syncChats')
    const {
      activeChat,
      activeSid,
      updateChatPushPro,
      updatedChats
    } = get()

    if (updatedChats.length === 0) return

    const chats = get().chats
    const updateData = updatedChats.map((chatSid) => {
      const chat = chats[chatSid]
      return {
        sid: chat.sid,
        dateUpdated: chat?.dateUpdated,
        lastMessage: chat?.lastMessage?.index,
        lastReadMessageIndex: chat?.lastReadMessageIndex,
      }
    })

    debounce(async () => {
      try {
        await matchfyApi.syncChats(updateData)

        if (activeChat?.type === CHAT_TYPES.PRO) {
          const updatedPushPro = await matchfyApi.getPushPro(activeSid)
          console.log('updatedPushPro', updatedPushPro)

          updateChatPushPro(
            queryClient,
            activeSid,
            updatedPushPro
          )
        }
      } catch (error) {
        console.error(error)
      }
    }, CHAT_SYNC_INTERVAL)()
  },
  sendSystemMessage: async (systemMessage) => {
    const { chats, activeSid } = get()
    if (!systemMessage || !activeSid || !chats?.[activeSid]) return

    const twilioChat = chats[activeSid]

    if (twilioChat) {
      await twilioChat.sendMessage(JSON.stringify({
        type: 'system',
        ...systemMessage
      }))
    }
  },
  updateChatListDetails: (chatSid, updateDetails) => {
    let list, listKey
    const chatList = get().chatList
    const archiveList = get().archiveList
    const updatedChats = get().updatedChats

    const listSearch = chatList.find((c) => c.sid === chatSid)
    const archiveSearch = archiveList.find((c) => c.sid === chatSid)

    if (!listSearch && !archiveSearch) return

    if (listSearch) {
      list = chatList
      listKey = 'chatList'
    }

    if (archiveSearch) {
      list = archiveList
      listKey = 'archiveList'
    }

    const chatIndex = list.findIndex((c) => c.sid === chatSid)

    // Merge the existing chat details with the update details
    list[chatIndex] = {
      ...list[chatIndex],
      ...updateDetails,
    }

    // Sort the chat list by date updated
    list.sort((a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated))

    // Add chat SID to updated chats if not already present
    if (!updatedChats.includes(chatSid)) {
      updatedChats.push(chatSid)
    }

    set({ [listKey]: list, updatedChats })
  },
  updateChatTimestamp: async (chatSid) => {
    get().updateChatListDetails(chatSid, {
      dateUpdated: new Date().toISOString(),
    })
  },
  updateChatUnread: async (chatSid, count = 0) => {
    get().updateChatListDetails(chatSid, {
      unreadCount: count,
    })
  },
  isChatArchived: (chatSid) => {
    const searchArchive = get().archiveList.find((c) => c.sid === chatSid)
    return !!searchArchive
  },
  archiveChat: async (queryClient, chatSid) => {
    set({ loading: true })
    try {
      await matchfyApi.archiveChat(chatSid)

      get().moveChat(
        'chatList',
        'archiveList',
        chatSid
      )
      set(() => ({ success: 'feedback.success.chat.archive' }))
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    } finally {
      set({ loading: false })
    }
  },
  unarchiveChat: async (queryClient, chatSid) => {
    set({ loading: true })
    try {
      await matchfyApi.unarchiveChat(chatSid)
      get().moveChat(
        'archiveList',
        'chatList',
        chatSid
      )
      set(() => ({ success: 'feedback.success.chat.unarchive' }))
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    } finally {
      set({ loading: false })
    }
  },
  isCurrentUserBlocked: () => {
    const activeChat = get().activeChat
    if (!activeChat) return false

    const blockedUsers = get().blockedList
    return blockedUsers.includes(activeChat.spotifyId)
  },
  blockUser: (userId) => {
    const blockedUsers = get().blockedList
    set(() => ({ blockedList: [...blockedUsers, userId] }))
  },
  unblockUser: (userId) => {
    const blockedUsers = get().blockedList
    set(() => ({ blockedList: blockedUsers.filter((id) => id !== userId) }))
  },
  deleteChat: async (queryClient, chatSid) => {
    if (!DEVELOPMENT) return
    set({ loading: true })

    try {
      await matchfyApi.deleteChat(chatSid)
      get().resetChat()
      set(() => ({ success: 'feedback.success.general.delete' }))

      set((state) => {
        const messages = { ...state.chatMessages }
        delete messages[chatSid]

        return {
          chatMessages: messages,
        }
      })
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    } finally {
      set({ loading: false })
    }
  },
  updateChatPushPro: async (queryClient, chatSid, update) => {
    try {
      const chatList = get().chatList
      const chatIndex = chatList.findIndex((c) => c.sid === chatSid)

      if (chatIndex !== -1 && chatList[chatIndex].pushPro) {
        const updatedChat = {
          ...chatList[chatIndex],
          pushPro: {
            ...chatList[chatIndex].pushPro,
            ...update
          }
        }

        get().setChatList([updatedChat])

        if (get().activeSid === chatSid) {
          set({ activeChat: updatedChat })
        }
      }
    } catch (error) {
      console.error(error)
    }
  },
}))

export default useChatStore
