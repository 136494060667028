import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAppStore = create(
  persist(
    (set) => ({
      version: null,
      completeRegistration: false,
      isNewUser: null,
      setIsNewUser: (isNewUser) => set({ isNewUser }),
      setCompleteRegistration: (completeRegistration) => set({ completeRegistration }),
      profileComplete: false,
      setProfileComplete: (profileComplete) => set({ profileComplete }),
      redirect: null,
      setRedirect: (redirect) => set({ redirect }),
      showBanner: false,
      setShowBanner: (showBanner) => set({ showBanner }),
      setVersion: (version) => set({ version }),
    }),
    {
      name: 'app-storage',
    }
  )
)

export default useAppStore
