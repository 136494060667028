import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { PUSH_PRO_TRACK_TYPES, PUSH_PRO_FEEDBACK } from '@/data'

export const usePushProFormStore = create(
  persist(
    (set) => ({
      pushPro: null,
      curatorId: null,
      success: false,
      formData: {
        trackType: PUSH_PRO_TRACK_TYPES[0].id,
        track: '',
        trackName: '',
        trackLink: '',
        artistId: '',
        artistName: '',
        artistImage: '',
        lyrics: '',
        artistBio: '',
        genres: [],
        typology: PUSH_PRO_FEEDBACK[0].id,
        notes: '',
      },
      setPushPro: (pushPro) => set({ pushPro }),
      setCuratorId: (curatorId) => set({ curatorId }),
      setSuccess: (success) => set({ success }),
      setFormData: (data) => set({ formData: data }),
      resetStatus: () => set({
        pushPro: null,
        curatorId: null,
        success: false
      }),
    }),
    {
      name: 'push-pro-form-storage',
    }
  )
)

export default usePushProFormStore 