import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const initialState = {
  companyName: '',
  roles: [],
  tags: [],
  type: 'all',
  popularity: { min: 0 }
}

export const useSearchStore = create(
  persist(
    (set, get) => ({
      search: initialState,
      isSearching: false,
      setSearch: (search) => set({ search }),
      setIsSearching: (isSearching) => set({ isSearching }),
      getSearchParams: () => {
        const { search } = get()
        const roles = search?.roles?.map((role) => role.value)

        return {
          companyName: search?.companyName,
          type: search?.type,
          roles,
          tags: search?.tags,
          popularity: search?.popularity,
        }
      }
    }),
    {
      name: 'search-storage',
    }
  )
)

export default useSearchStore
